import { m } from 'framer-motion';
import { forwardRef, ReactNode, Ref } from 'react';
import { Box, IconButton } from '@mui/material';

interface IconButtonAnimateProps {
  children: ReactNode;
  color?:
    | 'inherit'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
  size?: 'small' | 'medium' | 'large';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: Record<string, unknown>;
}

interface AnimateWrapProps {
  children: ReactNode;
  size: 'small' | 'medium' | 'large';
}

const IconButtonAnimate = forwardRef(
  ({ sx, children, size = 'medium', ...other }: IconButtonAnimateProps, ref: Ref<any>) => (
    <AnimateWrap size={size}>
      <IconButton size={size} ref={ref} {...other}>
        {children}
      </IconButton>
    </AnimateWrap>
  )
);

export default IconButtonAnimate;

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

const AnimateWrap = ({ size, children }: AnimateWrapProps) => {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  );
};
