import dynamic from 'next/dynamic.js';
import PropTypes from 'prop-types';

const DynamicLazyMotion = dynamic(() => import('framer-motion').then((mod) => mod.LazyMotion), {
  ssr: false,
});

const DynamicMDiv = dynamic(() => import('framer-motion').then((mod) => mod.m.div), { ssr: false });

const loadFeatures = () => import('./features.js').then((res) => res.default);

MotionLazyContainer.propTypes = {
  children: PropTypes.node,
};

export default function MotionLazyContainer({ children }) {
  return (
    <DynamicLazyMotion strict features={loadFeatures}>
      <DynamicMDiv style={{ height: '100%' }}>{children}</DynamicMDiv>
    </DynamicLazyMotion>
  );
}
