import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';

export default function useResponsive(query: string, key: number | Breakpoint, start: number | Breakpoint = 'xs') {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key as Breakpoint));
  const mediaDown = useMediaQuery(theme.breakpoints.down(key as Breakpoint));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, key));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(key as Breakpoint));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  if (query === 'only') {
    return mediaOnly;
  }

  return null;
}

export function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();

  return (
    keys.reduce((output: Breakpoint | null, key: Breakpoint) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null as Breakpoint | null) || 'xs'
  );
}

export function useAspectRatio() {
  const [aspectRatio, setAspectRatio] = useState({ isWidthGreater: false, percentDifference: 0 });

  useEffect(() => {
    const calculateAspectRatio = () => {
      const { innerWidth, innerHeight } = window;
      const isWidthGreater = innerWidth > innerHeight;
      const percentDifference = isWidthGreater
        ? ((innerWidth - innerHeight) / innerHeight) * 100
        : ((innerHeight - innerWidth) / innerWidth) * 100;

      setAspectRatio({ isWidthGreater, percentDifference });
    };

    calculateAspectRatio();

    window.addEventListener('resize', calculateAspectRatio);
    return () => {
      window.removeEventListener('resize', calculateAspectRatio);
    };
  }, []);

  return aspectRatio;
}
